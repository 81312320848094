<template>
  <base-dialog
    v-model="dialog"
    title="Muokkaa tilatietoa"
    icon="mdi-pencil"
    :valid-form="validForm"
    :is-loading="saving"
    @cancel="handleCancel"
    @save="saveStatus"
  >
    <template #content>
      <v-form v-model="validForm">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="description"
              :disabled="systemStatus"
              label="Nimi"
              filled
              hint='Esimerkki: "Matkalla"'
              required
              :rules="$rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Numeerinen arvo"
              hint='Käytä VIRVE-verkon status-arvoa, mikäli mahdollista. Esimerkki: "327001"'
              v-model.number="value"
              :disabled="systemStatus"
              filled
              required
              :rules="$rules.number"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            Taustaväri
          </v-col>
        </v-row>
        <v-row class="ml-3">
          <v-col>
            <v-color-picker
              v-model="colorBackground"
              flat
              show-swatches
              hide-canvas
              hide-inputs
              hide-mode-switch
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            Tekstin väri
          </v-col>
        </v-row>
        <v-row class="ml-3">
          <v-col>
            <v-color-picker
              v-model="colorText"
              flat
              show-swatches
              hide-canvas
              hide-inputs
              hide-mode-switch
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </base-dialog>
</template>
<style lang="sass" scoped></style>
<script>
import BaseDialog from "./BaseDialog.vue";
import FormMixin from "@/mixins/FormMixin";

export default {
  components: { BaseDialog },
  mixins: [FormMixin],
  props: {
    inModal: Boolean,
    active: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    validForm: false,
    saving: false
  }),
  computed: {
    dialog: {
      get() {
        return this.active;
      },
      set(value) {
        this.$emit("closed", value);
      }
    },
    id: {
      get() {
        return this.$store.state.status.editor.id;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.id", value]);
      }
    },
    value: {
      get() {
        return this.$store.state.status.editor.value;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.value", value]);
      }
    },
    description: {
      get() {
        return this.$store.state.status.editor.name;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.name", value]);
      }
    },
    systemStatus: {
      get() {
        return this.$store.state.status.editor.systemStatus;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.systemStatus", value]);
      }
    },
    colorText: {
      get() {
        return this.$store.state.status.editor.colorText;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.colorText", value]);
      }
    },
    colorBackground: {
      get() {
        return this.$store.state.status.editor.colorBackground;
      },
      set(value) {
        this.$store.commit("status/set", ["editor.colorBackground", value]);
      }
    },
    original() {
      return this.$store.state.status.status[this.statusId];
    },
    hasChanges() {
      return this.$store.getters["status/editorHasChanges"];
    }
  },
  watch: {
    validForm(value) {
      this.$emit("is-valid", value);
    }
  },
  methods: {
    async saveStatus() {
      const me = this;
      try {
        me.saving = true;
        await me.$store.dispatch("status/saveStatusInEditor");
        await me.$store.dispatch("status/load");
        me.saving = false;
        me.dialog = false;
        me.$toast({
          type: "success",
          message: `Muutokset tilatietoon '${me.description}' on tallennettu`
        });
      } catch (err) {
        if (err.message !== "cancel") {
          me.$error(err);
        }
        me.saving = false;
      }
    },
    handleCancel() {
      this.dialog = false;
    }
  }
};
</script>
